$(document).on('click', '.tab', function(el) {
  $('.tab-content, .tab').removeClass('active');

  tab_id = $(el.currentTarget).data('id');
  $(el.currentTarget).addClass('active');
  $(`#${tab_id}`).addClass('active');
});

$(document).on('click', '.link-to-tab-content', function(el) {
  el.preventDefault();
  $('.tab-content, .tab').removeClass('active');

  tab_content_id = $(el.currentTarget).attr('href');
  $(`.tab[data-id="${tab_content_id}"]`).addClass('active');
  $(`#${tab_content_id}`).addClass('active');
});
