$(document).on('click', '.spreadsheet-button', function(el) {
  $('.spreadsheet-popup-container').addClass('show-popup');
});

$(document).on('ajax:beforeSend', '.spreadsheet-popup-confirm', function(el) {
  var href = $(this).attr('href');

  $(this).attr('data-href', href);
  $(this).attr('href', '#');
});

$(document).on('ajax:success', '.spreadsheet-popup-confirm', function(el) {
  var data_href = $(this).data('href');

  $(this).attr('href', data_href);
  $(this).attr('data-href', null);

  $('.spreadsheet-popup-container').removeClass('show-popup');
});
