$(document).on('change', '.scans-select', function(el) {
  path = document.location.pathname;

  scan_id = $(el.target).val();

  if (path.includes('/scans')){
    path = path.replace(/scans\/\d+/, `scans/${scan_id}`);
  }else{
    path = `/home/${scan_id}`;
  }

  window.ga_tracker($(el.currentTarget).find(':selected'));

  window.location = path;
});
